import React from 'react'
import { Link } from 'react-router-dom'

export default class FooterOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
      <>
        <footer className="footer-one">
          <div className="footer-one__bottom">
            {/* <center>
              <img src={publicUrl + 'assets/images/resources/footer-logo.png'} alt="#" />
            </center> */}
            <div className="container">
              <div className="bottom-inner">
                <div className="copyright">
                  <p>Copyright © 2024 All Rights Reserved.</p>
                </div>

                <ul className="footer-one__bottom-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + `/about-us`}>Tentang Kami</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + `/contact-us`}>Hubungi Kami</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + `/privacy-policy`}>Kebijakan Privasi</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}
