import React from 'react'
import FooterOne from '../common/footer/FooterOne'
import HeaderTwo from 'common/header/HeaderTwo'
import AboutPrivacyPolicy from 'components/about/AboutPrivacyPolicy'
import ServiceThree from 'components/service/ServiceThree'
import CounterOne from 'components/counter/CounterOne'

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderTwo />
      <AboutPrivacyPolicy />
      <ServiceThree />
      <CounterOne />
      <FooterOne />
    </>
  )
}

export default PrivacyPolicy
