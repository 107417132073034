import React from 'react'

export default class AboutOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
      <>
        <section className="about-one">
          <div className="shape1 float-bob-y">
            <img src={publicUrl + 'assets/images/shapes/about-v1-shape1.png'} alt="#" />
          </div>
          <div className="shape2 float-bob-y">
            <img src={publicUrl + 'assets/images/shapes/about-v1-shape2.png'} alt="#" />
          </div>
          <div className="shape3"></div>
          <div
            className="about-one__bg"
            style={{
              backgroundImage: 'url(' + publicUrl + 'assets/images/about/about-v1-img1.png)',
            }}
          >
            {/* <div className="about-one__bg-content">
              <div className="img-box">
                <div className="inner">
                  <img src={publicUrl + 'assets/images/about/about-v1-img3.jpg'} alt="#" />
                </div>
                <div className="content-box">
                  <div className="icon-box">
                    <span className="icon-butterflies"></span>
                  </div>

                  <div className="text-box">
                    <h2>
                      Cash flow <br /> management
                    </h2>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-6"></div>
              {/* Start About One Content */}
              <div className="col-xl-6">
                <div className="about-one__content">
                  <div className="sec-title">
                    <div className="sec-title__tagline">
                      <h6>Tentang Kami </h6>
                      <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">Ekosistem Agrikultur</h2>
                  </div>

                  <div className="about-one__content-text1">
                    <p className="text2">
                      Agotani adalah aplikasi digital dalam ekosistem agrikultur, menghubungkan
                      petani, peternak, nelayan, serta pembeli individu atau perusahaan dalam sebuah
                      platform inovatif. Aplikasi ini menyediakan fasilitas bagi para pelaku
                      agrikultur untuk berkolaborasi secara efisien, dengan tujuan meningkatkan
                      produktivitas dan efektivitas dalam berbagai aspek kegiatan pertanian,
                      peternakan dan perikanan. <br />
                      Kami membawa misi untuk membantu mengatasi tantangan ekonomi dengan
                      mempercepat distribusi pada sektor agrikultur, sekaligus mendorong pertumbuhan
                      ekonomi daerah yang sesuai dengan karakteristik lokal. Melalui platform ini,
                      kami memberikan peluang kepada pelaku agrikultur untuk meningkatkan nilai
                      tambah pada hasil budidaya mereka dan memastikan pertumbuhan industri yang
                      berkelanjutan. Dengan komitmen kami, Agotani siap menjadi pemimpin dalam
                      memajukan sektor agrikultur, membantu mencegah krisis ketahanan pangan di
                      Indonesia, dan menciptakan peluang ekonomi yang merata bagi semua pihak yang
                      terlibat.
                    </p>
                  </div>
                </div>
              </div>
              {/* End About One Content */}
            </div>
          </div>
        </section>
      </>
    )
  }
}
