import React from 'react'
import { Link } from 'react-router-dom'

const Nav = () => {
  return (
    <ul className="main-menu__list">
      <li>
        <Link to={process.env.PUBLIC_URL + `/`}>
          Beranda <span className="line"></span>
        </Link>
      </li>

      <li>
        <Link to={process.env.PUBLIC_URL + `/about-us`}>
          Tentang Kami <span className="line"></span>
        </Link>
      </li>

      <li>
        <Link to={process.env.PUBLIC_URL + `/contact-us`}>
          Hubungi Kami <span className="line"></span>
        </Link>
      </li>
    </ul>
  )
}

export default Nav
