import React from 'react'
import { Link } from 'react-router-dom'
import Nav from './Nav'
import SearchButton from './SearchButton'
import LogoOne from './LogoOne'

export default class HeaderOne extends React.Component {
  render() {
    return (
      <>
        <header className="main-header main-header-one">
          {/* Start Main Header One Top */}
          <div className="main-header-one__top">
            <div className="auto-container">
              <div className="main-header-one__top-inner">
                <div className="main-header-one__top-left">
                  <ul className="main-header__contact-info">
                    <li>
                      <div className="inner">
                        <div className="icon-box">
                          <span className="icon-phone"></span>
                        </div>
                        <div className="text-box">
                          <p>WhatsApp: 0819 200 1688</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <div className="icon-box">
                          <span className="icon-email"></span>
                        </div>
                        <div className="text-box">
                          <p>cs@agotani.com</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="main-header-one__top-right">
                  <ul className="main-header-one__top-social-links">
                    <li>
                      <a href="https://www.facebook.com/agotaniapp">
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/agotaniapp">
                        <span className="icon-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* End Main Header One Top */}

          {/* Start Main Header One Bottom */}
          <div className="main-header-one__bottom">
            <div className="main-header-one__bottom-inner">
              <nav className="main-menu main-menu-one">
                <div className="main-menu__wrapper clearfix">
                  <div className="auto-container">
                    <div className="main-menu__wrapper-inner">
                      <div className="main-header-one__bottom-left">
                        <LogoOne />
                      </div>

                      <div className="main-header-one__bottom-middle">
                        <div className="main-menu-box">
                          {/* <MobileMenu /> */}
                          <Nav />
                        </div>
                      </div>

                      <div className="main-header-one__bottom-right">
                        <SearchButton />
                        <div className="main-header-one__bottom-right-btn">
                          <Link to={process.env.PUBLIC_URL + `/contact-us`}>Get A Quote</Link>
                        </div>

                        <div className="contact-box">
                          <div className="icon">
                            <span className="icon-chatting"></span>
                          </div>
                          <div className="text">
                            <p>Telp</p>
                            <a href="tel:926668880000">0819 200 1688</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          {/* End Main Header One Bottom */}
        </header>
      </>
    )
  }
}
