import React from 'react'

export default class AboutTwo extends React.Component {
  componentDidMount() {
    const $ = window.$

    if ($('.count-bar').length) {
      $('.count-bar').appear(
        function () {
          var el = $(this)
          var percent = el.data('percent')
          $(el).css('width', percent).addClass('counted')
        },
        {
          accY: -50,
        },
      )
    }

    if ($('.odometer').length) {
      var odo = $('.odometer')
      odo.each(function () {
        $(this).appear(function () {
          var countNumber = $(this).attr('data-count')
          $(this).html(countNumber)
        })
      })
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
      <>
        <section className="about-two">
          <div
            className="about-two__bg"
            style={{
              backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/about-v2-bg.png)',
            }}
          ></div>
          <div className="container">
            <div className="row">
              {/* Start About Two Img */}
              <div className="col-xl-6">
                <div className="about-two__img">
                  <div className="about-two__img1">
                    <img src={publicUrl + 'assets/images/about/about-v2-img1.jpg'} alt="#" />
                  </div>
                </div>
              </div>
              {/* End About Two Img */}

              {/* Start About Two Content */}
              <div className="col-xl-6">
                <div className="about-two__content">
                  <div className="sec-title style2">
                    <div className="sec-title__tagline">
                      <div className="img-box">
                        <img
                          src={publicUrl + 'assets/images/resources/sec-title-img2.png'}
                          alt="#"
                        />
                      </div>
                      <h6>Agotani</h6>
                    </div>
                    <h2 className="sec-title__title">Tentang Kami</h2>
                  </div>

                  <div className="about-two__content-text1">
                    <p>
                      Agotani adalah aplikasi digital dalam ekosistem agrikultur, menghubungkan
                      petani, peternak, nelayan, serta pembeli individu atau perusahaan dalam sebuah
                      platform inovatif. Aplikasi ini menyediakan fasilitas bagi para pelaku
                      agrikultur untuk berkolaborasi secara efisien, dengan tujuan meningkatkan
                      produktivitas dan efektivitas dalam berbagai aspek kegiatan pertanian,
                      peternakan dan perikanan. <br />
                      Kami membawa misi untuk membantu mengatasi tantangan ekonomi dengan
                      mempercepat distribusi pada sektor agrikultur, sekaligus mendorong pertumbuhan
                      ekonomi daerah yang sesuai dengan karakteristik lokal. Melalui platform ini,
                      kami memberikan peluang kepada pelaku agrikultur untuk meningkatkan nilai
                      tambah pada hasil budidaya mereka dan memastikan pertumbuhan industri yang
                      berkelanjutan. Dengan komitmen kami, Agotani siap menjadi pemimpin dalam
                      memajukan sektor agrikultur, membantu mencegah krisis ketahanan pangan di
                      Indonesia, dan menciptakan peluang ekonomi yang merata bagi semua pihak yang
                      terlibat.
                    </p>
                  </div>
                </div>
              </div>
              {/* End About Two Content */}
            </div>
          </div>
        </section>
      </>
    )
  }
}
