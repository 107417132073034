import React from 'react'
import ServiceThree from '../components/service/ServiceThree'
import CounterOne from '../components/counter/CounterOne'
import FooterOne from '../common/footer/FooterOne'
import HeaderTwo from 'common/header/HeaderTwo'
import AboutTwo from 'components/about/AboutTwo'

const About = () => {
  return (
    <>
      <HeaderTwo />
      <AboutTwo />
      <ServiceThree />
      <CounterOne />
      <FooterOne />
    </>
  )
}

export default About
