import React from 'react'
import ContactForm from '../components/contact/ContactForm'
import ContactMap from '../components/contact/ContactMap'
import FooterOne from '../common/footer/FooterOne'
import HeaderTwo from 'common/header/HeaderTwo'

const Contact = () => {
  return (
    <>
      <HeaderTwo />
      <ContactForm />
      <ContactMap />
      <FooterOne />
    </>
  )
}

export default Contact
