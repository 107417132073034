import React from 'react'
import HeaderThree from '../common/header/HeaderThree'
import HeroThree from '../components/hero/HeroThree'
import FeatureTwo from '../components/feature/FeatureTwo'
import AboutThree from '../components/about/AboutThree'
import ServiceThree from '../components/service/ServiceThree'
import CounterOne from '../components/counter/CounterOne'
import FaqTwo from '../components/faq/FaqTwo'
import TeamTwo from '../components/team/TeamTwo'
import PricingTwo from '../components/pricing/PricingTwo'
import ProjectThree from '../components/project/ProjectThree'
import TestimonialTwo from '../components/testimonial/TestimonialTwo'
import BlogThree from '../components/blog/BlogThree'
import QuoteOne from '../components/quote/QuoteOne'
import FooterOne from '../common/footer/FooterOne'

const HomeThree = () => {
  return (
    <>
      <HeaderThree />
      <HeroThree />
      <FeatureTwo />
      <AboutThree />
      <ServiceThree />
      <CounterOne />
      <FaqTwo />
      <TeamTwo />
      <PricingTwo />
      <ProjectThree />
      <TestimonialTwo />
      <BlogThree />
      <QuoteOne />
      <FooterOne />
    </>
  )
}

export default HomeThree
