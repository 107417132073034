import React from 'react'
import HeaderOne from '../common/header/HeaderOne'
import HeroTwo from '../components/hero/HeroTwo'
import WhyChooseOne from '../components/why-choose-us/WhyChooseOne'
import AboutOne from '../components/about/AboutOne'
import CtaOne from '../components/cta/CtaOne'
import WorkProcessOne from '../components/work-process/WorkProcessOne'
import FooterOne from '../common/footer/FooterOne'

const HomeDefault = () => {
  return (
    <>
      <HeaderOne />
      <HeroTwo />
      <WhyChooseOne />
      <AboutOne />
      <CtaOne />
      {/* <BrandOne /> */}
      {/* <ContactOne /> */}
      {/* <ServiceOne /> */}
      {/* <ProjectOne /> */}
      <WorkProcessOne />
      {/* <FaqOne /> */}
      {/* <TestimonialOne /> */}
      {/* <ExcellentWorkOne /> */}
      {/* <BlogOne /> */}
      <FooterOne />
    </>
  )
}

export default HomeDefault
