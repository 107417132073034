import React from 'react'

export default class ContactMap extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
      <>
        <section className="contact-page-bottom">
          <div className="container">
            <div className="row">
              {/* Start Contact Page Bottom Map */}
              <div className="col-xl-6">
                <div className="contact-page-bottom-map">
                  <iframe
                    title="Address"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126905.29079766783!2d106.64496489726558!3d-6.291264199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f145843a4ee7%3A0xf9da4d595d08f67f!2sThe%20CEO%20Building%20(Cilandak%20Executive%20Office)!5e0!3m2!1sen!2sid!4v1707959067095!5m2!1sen!2sid"
                    className="contact-page__google-map"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
              {/* End Contact Page Bottom Map */}

              {/* Start Contact Page Bottom Content */}
              <div className="col-xl-6">
                <div className="contact-page-bottom__content">
                  <div className="sec-title">
                    <div className="sec-title__tagline">
                      <h6>Hubungi Kami</h6>
                      <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">Customer Care</h2>
                  </div>
                  <div className="contact-page-bottom__content-img">
                    <img
                      src={publicUrl + 'assets/images/resources/contact-page-bottom-img.jpg'}
                      alt="#"
                    />

                    <div className="contact-info">
                      <ul>
                        <li>
                          <div className="inner">
                            <div className="icon-box">
                              <span className="icon-phone-call-1"></span>
                            </div>

                            <div className="content-box">
                              <p>
                                {' '}
                                Tel : <a href="tel:50919675">021-50919675</a>
                              </p>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inner">
                            <div className="icon-box">
                              <span className="icon-message"></span>
                            </div>

                            <div className="content-box">
                              <p> Email : cs@agotani.com</p>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inner">
                            <div className="icon-box">
                              <span className="icon-placeholder"></span>
                            </div>

                            <div className="content-box">
                              <p>Add : The CEO Building, Jakarta</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Contact Page Bottom Content */}
            </div>
          </div>
        </section>
      </>
    )
  }
}
