import React from 'react'
import HeaderTwo from '../common/header/HeaderTwo'
import HeroTwo from '../components/hero/HeroTwo'
import FeatureOne from '../components/feature/FeatureOne'
import AboutTwo from '../components/about/AboutTwo'
import CtaOne from '../components/cta/CtaOne'
import ServiceTwo from '../components/service/ServiceTwo'
import ProjectTwo from '../components/project/ProjectTwo'
import TeamOne from '../components/team/TeamOne'
import BrandTwo from '../components/brand/BrandTwo'
import WorkProcessTwo from '../components/work-process/WorkProcessTwo'
import PricingOne from '../components/pricing/PricingOne'
import TestimonialTwo from '../components/testimonial/TestimonialTwo'
import BlogTwo from '../components/blog/BlogTwo'
import FooterOne from '../common/footer/FooterOne'

const HomeTwo = () => {
  return (
    <>
      <HeaderTwo />
      <HeroTwo />
      <FeatureOne />
      <AboutTwo />
      <CtaOne />
      <ServiceTwo />
      <ProjectTwo />
      <TeamOne />
      <BrandTwo />
      <WorkProcessTwo />
      <PricingOne />
      <TestimonialTwo />
      <BlogTwo />
      <FooterOne />
    </>
  )
}

export default HomeTwo
