import React from 'react'
import { Link } from 'react-router-dom'

export default class CtaOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
      <>
        <section className="slogan-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="slogan-one__inner">
                  <div className="shape1">
                    <img src={publicUrl + 'assets/images/shapes/slogan-v1-shape1.png'} alt="#" />
                  </div>
                  <div
                    className="slogan-one__bg"
                    style={{
                      backgroundImage:
                        'url(' + publicUrl + 'assets/images/backgrounds/slogan-v1-bg.png)',
                    }}
                  ></div>
                  <div className="content-box">
                    <h2>
                      Download Agotani di Play Store <br />
                    </h2>
                    <small className="text-white">Aplikasi Digital Agrikultur</small>
                  </div>

                  <div className="content-box">
                    <Link to="https://play.google.com/store/apps/details?id=com.agotani.app">
                      <img src={publicUrl + 'assets/images/agotani-play-store.png'} alt="#" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
