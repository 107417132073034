import React, { useCallback, useEffect, useState } from 'react'
import ContactForm from '../components/contact/ContactForm'
import ContactMap from '../components/contact/ContactMap'
import FooterOne from '../common/footer/FooterOne'
import HeaderTwo from 'common/header/HeaderTwo'
import axios from 'axios'

const ConfirmEmail = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const [token] = useState(queryParams.get('token'))
  const [loading, setLoading] = useState(true)

  const confirmationRequest = useCallback((token) => {
    const options = {
      method: 'POST',
      url: 'https://agotani-api.galotek.com/v1/public/authentication/confirm-email',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        access: {
          geolocation: null,
          devices: null,
          language: 'ID',
          application: 'WEB_PORTAL',
          account_uid: '',
          account_name: '',
        },
        payload: {
          token: token,
        },
      },
    }
    axios(options)
      .then(() => {
        window.location.href = '/'
      })
      .catch(() => {
        window.location.href = '/'
      })
  }, [])

  useEffect(() => {
    if (loading) {
      setLoading(false)
      if (token !== null && token !== '') {
        confirmationRequest(token)
      }
    }
  }, [confirmationRequest, loading, token])

  return (
    <>
      <HeaderTwo />
      <ContactForm />
      <ContactMap />
      <FooterOne />
    </>
  )
}

export default ConfirmEmail
